const genericFiltersModule = () => import(/* webpackChunkName: 'components/genericFilters' */'../../components/genericFilters/genericFilters');

import { blogArticleTpl } from './blogArticleTpl';

import Masonry from 'masonry-layout';

jQueryBridget('masonry', Masonry, $);

$(() => {
  new BlogHubView();
});

export const BlogHubView = Backbone.View.extend({
  el: $('#js-hubContainer'),

  initialize: function () {

    this.page = 0;
    this.tags = '';
    this.action = '';
    this.masonryActive = false;

    this.initMsnry();
    this.initPagination();
    this.initBlogFiltersPosition();

    genericFiltersModule().then(({GenericFilterView}) => {
      const filtersOptions = {
        element: $('.js-genericFilters')
      };

      new GenericFilterView(filtersOptions);
      this.initFilters();
    });

    resize.addToFunctionList(this.initMsnry, false, this);
  },

  initMsnry() {
    if (this.masonryActive) {
      this.$msnryGrid.masonry('destroy');
      this.masonryActive = false;
    }

    if (!DEVICE.isMobile) {
      this.$msnryGrid = $('#container').masonry({
        columnWidth: '.grid-sizer',
        itemSelector: '.masonryBox',
        fitWidth: true
      });

      $('.masonryBox img').on('load', () => {
        this.$msnryGrid.masonry('reloadItems').masonry();
      });

      dispatcher.trigger('lazyload:update');
      this.masonryActive = true;
    }
  },

  // handling click on pagination button
  initPagination() {
    $('.js-blogPagination').on('click', event => {
      event.preventDefault();
      this.page = this.page + 1;
      this.action = 'pagination';
      this.updateArticles();
    });
  },

  // handling events on filter checkboxes and searchbar
  initFilters() {
    const $submit = $('div.searchFilters-closeFilterButton'),
      $submitFreesearch = $('.js-blog-freesearch'),
      submitExist = $submit.length && $submit.css('display') !== 'none';

    if (submitExist) {
      $submit.find('.submitSearchEngine').on('click', () => {
        this.updateArticles();
      });
    }

    $submitFreesearch.on('click', () => {
      this.updateArticles();
    });

    $('.blogSearch__input').on('keypress', function (e) {
      if (e.which === 13) {
        $submitFreesearch.click();
      }
    });

    $('.js-blogFilters .form-checkbox').on('change', () => {
      this.action = 'filters';
      this.page = 0;
      if (!submitExist) {
        this.updateArticles();
      }
    });
  },

  closeFilters(){
    $('.js-filterOpener').parent().removeClass('opened');

    setTimeout(function () {
      $('.fancybox-close-small').click();
      button.clearButtonLoader();
    }, 100);
  },

  initBlogFiltersPosition() {
    if (DEVICE.isMobile) {
      return;
    }

    $(document).ready(() => {
      const $jsHubContainer = $('section#js-hubContainer');
      const $blogHubGridFilter = $jsHubContainer.find('div.blogHub-grid.js-blogFilters');
      const $blogHubGridContent = $jsHubContainer.find('div#content');
      const $searchBlock = $blogHubGridFilter.find('div.searchBlock');
      const $blogHubGridContentGridContainer = $blogHubGridContent.find('div.gridContainer');

      // timeout to render width after masonry is built and check if is empty
      setTimeout(function () {
        let defaultWidth;
        if (!$('.CommonContentBlock').length) {
          defaultWidth = '100%';
        } else {
          defaultWidth = $blogHubGridContentGridContainer.css('width');
        }

        // historic build
        $searchBlock.css({
          'text-align': 'right',
          'padding': '1%',
          'width': defaultWidth,
          'max-width': $blogHubGridContentGridContainer.css('max-width')
        });
      }, 50);
    });
  },

  // getting list of actives tags/filters
  getFilters() {
    let strFilters = '';
    let strSegments = '',
      strParks = '',
      strCategories = '',
      strActivities = '';
    $('.form-checkbox:checked').each((index, checkbox) => {
      switch ($(checkbox).data('type')) {
        case 'segment':
          strSegments = strSegments + (strSegments == '' ? '' : ',') + $(checkbox).val();
          break;
        case 'park':
          strParks = strParks + (strParks == '' ? '' : ',') + $(checkbox).val();
          break;
        case 'category':
          strCategories = strCategories + (strCategories == '' ? '' : ',') + $(checkbox).val();
          break;
        case 'activity':
          strActivities = strActivities + (strActivities == '' ? '' : ',') + $(checkbox).val();
          break;
      }
    });
    if (strSegments != '') {
      strFilters = '&segments=' + strSegments;
    }
    if (strParks != '') {
      strFilters = strFilters + '&parks=' + strParks;
    }
    if (strCategories != '') {
      strFilters = '&categories=' + strCategories;
    }
    if (strActivities != '') {
      strFilters = '&activities=' + strActivities;
    }

    if (typeof BLOG_SCRIPTS != 'undefined') {
      if (BLOG_SCRIPTS.CURRENT_TAG != '') {
        strFilters += '&tags=' + BLOG_SCRIPTS.CURRENT_TAG;
      }

      if (BLOG_SCRIPTS.CURRENT_SECTION != '') {
        strFilters += '&section=' + BLOG_SCRIPTS.CURRENT_SECTION;
      }
    }
    return strFilters;
  },

  getUrlFilters() {
    let strFilters = '';
    let strUrlSegments = '',
      strUrlParks = '',
      strUrlCategories = '',
      strUrlActivities = '';
    $('.form-checkbox:checked').each((index, checkbox) => {
      switch ($(checkbox).data('type')) {
        case 'segment':
          strUrlSegments = strUrlSegments + '&segments[]=' + $(checkbox).val();
          break;
        case 'park':
          strUrlParks = strUrlParks + '&parks[]=' + $(checkbox).val();
          break;
        case 'category':
          strUrlCategories = strUrlCategories + '&categories[]=' + $(checkbox).val();
          break;
        case 'activity':
          strUrlActivities = strUrlActivities + '&activities[]=' + $(checkbox).val();
          break;
      }
    });
    if (strUrlSegments != '') {
      strFilters = strUrlSegments;
    }
    if (strUrlParks != '') {
      strFilters = strFilters + strUrlParks;
    }
    if (strUrlCategories != '') {
      strFilters = strFilters + strCategories;
    }
    if (strUrlActivities != '') {
      strFilters = strFilters + strActivities;
    }

    if (typeof BLOG_SCRIPTS != 'undefined') {
      if (BLOG_SCRIPTS.CURRENT_TAG != '') {
        strFilters += '&tags=' + BLOG_SCRIPTS.CURRENT_TAG;
      }

      if (BLOG_SCRIPTS.CURRENT_SECTION != '') {
        strFilters += '&section=' + BLOG_SCRIPTS.CURRENT_SECTION;
      }
    }
    return strFilters;
  },

  getUrlSearch() {
    let strSearch = '';
    strSearch = $('input.blogSearch__input').val();
    return strSearch;
  },

  //displaying articles
  render(articleList, isListComplete) {
    const articleListContainer = this.$el.find('#container'),
      nbArticles = articleList.length;
    let htmlArticleList = '';
    //build new content
    $(articleList).each((index, article) => {
      const isFirst = this.action == 'filters' && index == 0,
        modifierBlock = nbArticles == 1 ? ' r-all--1of1' : isFirst == true ? ' r-all--1of1 r-minM--2of3' : 'r-all--1of3 r-all--1of1 r-minM--1of3';
      htmlArticleList = htmlArticleList + blogArticleTpl(article, modifierBlock);
    });

    //update html
    if (this.action == 'pagination') {
      if (!DEVICE.isMobile) {
        const $content = $(htmlArticleList);
        this.$msnryGrid.append($content);
        //delay mandatory for mansonry taking account of image size
        setTimeout(() => {
          this.$msnryGrid.masonry('appended', $content);
        }, 500);
      } else {
        $(articleListContainer).append(htmlArticleList);
      }

      $('.masonryBox img').on('load', () => {
        this.$msnryGrid.masonry('reloadItems').masonry();
      });

      dispatcher.trigger('lazyload:update');
    } else {
      //no result for search
      if (!articleList.length) {
        $('.searchBlock ').css('width', '90%');
        if (this.masonryActive) {
          this.$msnryGrid.masonry('destroy');
          this.masonryActive = false;
          $('#container').html('<div class="blogHub__no-result"><p>' + BLOG_SCRIPTS.SEARCH_IS_EMPTY.search_is_empty + '</p></div>');
        }
      } else {
        //adding sizers
        htmlArticleList = htmlArticleList + '<div class="grid-sizer"></div><div class="gutter-sizer"></div>';
        //replace current content by new content
        $(articleListContainer).html(htmlArticleList);
        this.initMsnry();
      }
    }
    //handling pagination button
    if (isListComplete) {
      $('.js-blogPagination').hide();
    } else {
      $('.js-blogPagination').show();
    }

    this.closeFilters();
  },

  //getting updated datas and triggering the render
  updateArticles() {
    const strFilters = this.getFilters(),
      strUrlFilters = this.getUrlFilters(),
      strUrlSearch = this.getUrlSearch(),
      urlBypass = '/bypass/json/flockler',
      baseParams = '?univers=18&language=' + GLOBAL_SCRIPTS.LANGUAGE_CODE + '&market=' + GLOBAL_SCRIPTS.MARKET_CODE,
      additionnalParams = '&limit=10' + (strUrlFilters ? ('&page=' + this.page + strUrlFilters) : '&page=' + this.page) + strFilters + (strUrlSearch ? ('&freesearch=' + strUrlSearch) : ''),
      urlBlog = GLOBAL_SCRIPTS.URL_CURRENT.indexOf('?') > 0 ? GLOBAL_SCRIPTS.URL_CURRENT.split('?')[0] : GLOBAL_SCRIPTS.URL_CURRENT,
      urlFilter = urlBlog + baseParams + additionnalParams,
      url = urlBypass + baseParams + additionnalParams;

    const successCallback = datas => {
      if (datas.status == 'success') {
        const articleList = datas.results.datas,
          totalArticles = datas.results.total,
          limit = datas.results.limit,
          numPage = datas.results.page,
          totalArticlesDisplayed = limit * (numPage + 1),
          isListComplete = totalArticlesDisplayed >= totalArticles;
        this.render(articleList, isListComplete);
        history.pushState(null, 'titre', urlFilter);
      }
    };

    //getting datas by ajax call
    const ajaxData = {
      ajaxUrl: url,
      ajaxParams: {},
      successCallback: successCallback,
      abortable: true,
      origin: 'blogUpdate',
      dataType: 'json',
      type: 'GET'
    };

    genericAjaxCall(ajaxData);
  }
});